import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBIcon,
  MDBCardSubTitle,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBCol,
  MDBCardTitle,
  MDBRow,
  MDBBtn,
} from "mdb-react-ui-kit";

const ARGATeamCarousel = () => {
  const StaffLeader = ({ id, imageData, title, subtitle, text, }) => (
    <MDBCard
      id={id}
      className="mx-1 py-2 align-items-center h-100"
      style={{ maxWidth: "800px" }}
    >
      <GatsbyImage
        image={imageData}
        placeholder="tracedSVG"
        position="top"
        style={{ maxWidth: "10rem" }}
        alt="..."
        className="mt-4"
      />
      <MDBCardBody>
        <MDBCardTitle className="text-primary fs-2 text-center pb-2">
              {title}
            </MDBCardTitle>
            <MDBCardSubTitle
              className="text-center pb-2"
              style={{ fontSize: "20px" }}
            >
              Fundadora de{" "}
              <span className="text-primary" style={{ fontWeight: "bold" }}>
                ARGA Intercultural
              </span>
            </MDBCardSubTitle>
        <MDBCardText className="text-secondary pt-2 px-0 mb-0 text-start">
          <MDBIcon fas icon="quote-left" className="text-black-50" />
          {/* " " + text + " " + collapsed + " " */}
          {" " + text + " "}
          <MDBIcon fas icon="quote-right" className="text-black-50" />
        </MDBCardText>
        <MDBRow className="text-center">
            <Link to="/blog/gabriela-kary" className="button pt-2">
              <MDBBtn color="link" style={{ fontWeight: "bold" }}>
                Conoce más
              </MDBBtn>
            </Link>
          </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );

  const StaffCard = ({ id, imageData, title, subtitle, text, collapsed }) => (
    <MDBCard
      id={id}
      className="mx-1 py-2 align-items-center h-100"
    >
      <GatsbyImage
        image={imageData}
        placeholder="tracedSVG"
        position="top"
        style={{ maxWidth: "8rem" }}
        alt="..."
        className="mt-4"
      />
      <MDBCardBody>
        <p className="text-primary fs-4 text-center">{title}</p>
        <MDBCardSubTitle className="text-center">{subtitle}</MDBCardSubTitle>
        <MDBCardText className="text-secondary pt-2 px-0 text-start">
          <MDBIcon fas icon="quote-left" className="text-black-50" />
          {/* " " + text + " " + collapsed + " " */}
          {" " + text + " "}
          <MDBIcon fas icon="quote-right" className="text-black-50" />
        </MDBCardText>
        <MDBRow className="text-center">
          <MDBPopover
            color="link"
            btnChildren="Conoce más"
            style={{ fontWeight: "bold" }}
            size="sm"
            placement="top"
            dismiss
            className="p-0 m-0 "
            poperStyle={{ fontFamily: "Oswald, sans-serif", fontStyle: "normal" }}
          >
            <MDBPopoverHeader>
              {title} - {subtitle}
            </MDBPopoverHeader>
            <MDBPopoverBody>{collapsed}</MDBPopoverBody>
          </MDBPopover>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  );

  const avatars = useStaticQuery(
    graphql`
      query {
        gabriela: file(relativePath: { eq: "staff/GabrielaKary.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        angeles: file(relativePath: { eq: "staff/AngelesFalcon.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        karin: file(relativePath: { eq: "staff/KarinMarchini.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        lara: file(relativePath: { eq: "staff/LaraPeker.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        luciana: file(relativePath: { eq: "staff/LucianaSlaen.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        alejandra: file(relativePath: { eq: "staff/AlejandraSuarez.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        gabcab: file(relativePath: { eq: "staff/GabrielaCabrera.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        moira: file(relativePath: { eq: "staff/MoiraLaSerna.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        nadia: file(relativePath: { eq: "staff/NadiaPerez.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        ners: file(relativePath: { eq: "staff/NersRodriguez.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        yanina: file(relativePath: { eq: "staff/YaninaCamilletti.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        paula: file(relativePath: { eq: "staff/PaulaMateu.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        soledad: file(relativePath: { eq: "staff/SoledadKonic.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        agostina: file(relativePath: { eq: "staff/AgostinaMolteni.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        marcela: file(relativePath: { eq: "staff/MarcelaMeinardi.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        mariaaguaisol: file(relativePath: { eq: "staff/MariaAguaisol.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        micaela: file(relativePath: { eq: "staff/MicaelaRanalli.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const fundadora = [
    {
      id: "CardFundadora",
      image: getImage(avatars.gabriela),
      title: "Gabriela Kary",
      subtitle: "Fundadora de ARGA Intercultural",
      text: "Soy Traductora Pública Nacional de Inglés, egresada de la Universidad Nacional de Córdoba, Argentina. En 2014 fundé ARGA Intercultural y vivo en Santiago De Chile desde mediados de 2018. Tengo más de diez años de experiencia en enseñanza y traducción, con un perfil emprendedor, proactivo y resiliente. Me gusta brindar soluciones de manera eficaz y creo firmemente en el trabajo colaborativo que suma.",
    },
  ];

  const staff = [
    {
      id: "CardCoordinadoras",
      image: getImage(avatars.mariaaguaisol),
      title: "María Florencia Aguaisol",
      subtitle: "Coordinadora de Proyectos",
      text: "Estudio traductorado de inglés en la Universidad Nacional de Córdoba, y en ARGA me desempeño como Coordinadora de Proyectos. Tengo experiencia como traductora, editora, transcriptora, qcer, team leader...",
      collapsed:
        "Tengo experiencia como traductora, editora, transcriptora, qcer, team leader y coach de inglés. Me gusta trabajar en equipo, intercambiar ideas y resolver situaciones.",
    },
    {
      id: "CardCoordinadoras",
      image: getImage(avatars.micaela),
      title: "Micaela Gonzalez Ranalli",
      subtitle: "Coordinadora de Comunicación",
      text: "Soy Licenciada en Relaciones Internacionales, y en ARGA trabajo como coordinadora del área de Comunicación Interna. Me encanta mi trabajo porque es dinámico. Siempre hay algo para hacer y me acompaña un...",
      collapsed:
        "Siempre hay algo para hacer y me acompaña un gran equipo. Me gustaría hacer un aporte significativo en ARGA y que sigamos creciendo juntos. Como dato curioso: Estoy aprendiendo lengua de señas.",
    },
    {
      id: "CardCoaches",
      image: getImage(avatars.angeles),
      title: "Ángeles Falcón",
      subtitle: "Coach",
      text: "Soy Traductora Pública de Inglés, egresada de la Universidad Católica Argentina. Además de mi rol como coach de inglés en ARGA, me desempeño como traductora literaria y audiovisual de forma independiente.",
      collapsed:
        "Lo que más disfruto de mi profesión es el aprendizaje constante y el contacto con personas de diferentes culturas.",
    },
    {
      id: "CardCoaches",
      image: getImage(avatars.karin),
      title: "Karina Marchini",
      subtitle: "Coach",
      text: "Estudié inglés en AACI y en el Instituto Superior del Profesorado Dr. Joaquín V. González. Me gradué de traductora literaria y técnico científica en el año 2016 y me especializo en traducción audiovisual.",
      collapsed:
        "Traduzco y adapto guiones para doblaje y realizo subtitulado de material televisivo. Tengo más de quince años de experiencia en docencia y he traducido dos libros que actualmente están a la venta.",
    },
    {
      id: "CardCoaches",
      image: getImage(avatars.lara),
      title: "Lara Peker",
      subtitle: "Coach",
      text: "Soy Profesora en Español egresada de la Universidad Nacional de Córdoba, Argentina. En ARGA me desempeño como coach de español. Me apasiona enseñar mi lengua porque es un trabajo dinámico y creativo...",
      collapsed:
        "Me apasiona enseñar mi lengua porque es un trabajo dinámico y creativo en el que necesito hasta reflexionar acerca de mi pensamiento y cultura. De alguna manera, enseñar español me permite conocerme a mí misma.",
    },
    {
      id: "CardCoaches",
      image: getImage(avatars.luciana),
      title: "Luciana Slaen",
      subtitle: "Coach",
      text: "Enseño inglés y español a extranjeros desde hace más de diez años. Me hace feliz compartir los logros de mis estudiantes cuando alcanzan sus objetivos y superan obstáculos en su aprendizaje, y también...",
      collapsed:
        "y también estar ahí para ayudarles y animarles cuando la motivación y las ganas decaen. Soy una apasionada de las lenguas así que también he estudiado francés y actualmente estudio ruso.",
    },
    {
      id: "CardCoaches",
      image: getImage(avatars.gabcab),
      title: "María Gabriela Cabrera",
      subtitle: "Coach",
      text: "He trabajado en enseñanza pública y posteriormente dando clases online, con treinta años de experiencia en la profesión. Durante 2020 y 2021 cursé el Interpretariado en inglés en la facultad.",
      collapsed:
        "En mi tiempo libre me dedico al canto coral. He sido miembro de distintos coros desde el año 2010 y en Buenos Aires ya soy miembro de un grupo. And what else can I say? This is me!",
    },
    {
      id: "CardCoaches",
      image: getImage(avatars.alejandra),
      title: "Alejandra Suarez",
      subtitle: "Coach",
      text: "Me encanta mi profesión ya que me permite relacionarme con gente de diferentes culturas, lo cual es muy enriquecedor. Soy una persona tímida, por lo tanto la carrera que elegí es un desafío para mí...",
      collapsed:
        "Soy una persona tímida, por lo tanto la carrera que elegí es un desafío para mí, ya que el enseñar hace que uno sea el foco de atención. Como también soy una persona muy alegre, intento contagiar mi alegría y mi pasión por el idioma a mis estudiantes.",
    },
    {
      id: "CardCoaches",
      image: getImage(avatars.moira),
      title: "Moira La Serna",
      subtitle: "Coach",
      text: "En mis clases me interesa que los alumnos se puedan sentir cómodos, que el aprendizaje parta de poder disfrutarlo y que se sientan en confianza. Disfruto de ver cómo mis estudiantes avanzan y logran sus...",
      collapsed:
        "Disfruto de ver cómo mis estudiantes avanzan y logran sus objetivos, que incrementen sus fortalezas en el idioma y que también les sirva tanto para su profesión como para su desarrollo individual.",
    },
    {
      id: "CardCoaches",
      image: getImage(avatars.nadia),
      title: "Nadia Perez",
      subtitle: "Coach",
      text: "Soy coach de italiano, con estudios en la Universidad Nacional de Córdoba (Argentina). Desde hace cuatro años doy clases de idioma, y he tenido la linda experiencia de trabajar tanto con niños como...",
      collapsed:
        "Desde hace cuatro años doy clases de idioma, y he tenido la linda experiencia de trabajar tanto con niños como con adultos, incluso con adultos mayores.",
    },
    {
      id: "CardCoaches",
      image: getImage(avatars.ners),
      title: "Nersy Rodriguez",
      subtitle: "Coach",
      text: "Estudié en la Universidad Pedagógica en Cuba, de la que me gradué con el título Licenciada en Lengua Inglesa en 1994. El excelente ambiente laboral y la entrega de mis alumnos me han permitido sentirme...",
      collapsed:
        "El excelente ambiente laboral y la entrega de mis alumnos me han permitido sentirme sumamente cómoda en mi rol dentro de ARGA.",
    },

    {
      id: "CardCoaches",
      image: getImage(avatars.yanina),
      title: "Yanina Camilletti",
      subtitle: "Coach",
      text: "Me gradué de Traductora Pública en idioma inglés, y actualmente estoy terminando de cursar el tramo pedagógico porque amo enseñar. Me gusta poder combinar la traducción con la enseñanza. Me apasionan los...",
      collapsed:
        "Me apasionan los idiomas. Me encanta viajar y conocer nuevas culturas. Viví unos meses en España e Italia. Me enamoré de la lengua italiana, así que ¡comencé a estudiarla!",
    },
    {
      id: "CardTraductoras",
      image: getImage(avatars.paula),
      title: "Paula Mateu",
      subtitle: "Traductora",
      text: "Desde el 2018 ejerzo como traductora de inglés. Últimamente, me he dedicado mayormente a la traducción de contenidos relacionados a la educación y a la salud. Mi área preferida es la audiovisual.",
      collapsed:
        "Tengo experiencia en subtitulado y realicé cursos de adaptación de traducciones para doblaje. También he trabajado en transcripciones y en la adaptación de contenido audiovisual para personas con discapacidades sensoriales.",
      },
      {
      id: "CardTraductoras",
      image: getImage(avatars.soledad),
      title: "Soledad Konic",
      subtitle: "Traductora",
      text: "Soy traductora de inglés, graduada de la Facultad de Lenguas de la Universidad Nacional de Córdoba, Argentina. Además de mi rol en ARGA, trabajo como traductora independiente y mi área de especialidad...",
      collapsed:
        "Además de mi rol en ARGA, trabajo como traductora independiente y mi área de especialidad es la sostenibilidad. También trabajo con proyectos de las áreas de marketing, educación, ciencias sociales y literatura.",
    },
    {
      id: "CardTraductoras",
      image: getImage(avatars.agostina),
      title: "Agostina Molteni",
      subtitle: "Traductora",
      text: "Soy traductora inglés-español, egresada de la Facultad de Lenguas de la Universidad Nacional de Córdoba. Trabajo como traductora autónoma y me especializo en educación, humanidades, ecología y medio ambiente.",
      collapsed:
        "Realicé una diplomatura en localización de contenido multimedia y me estoy especializando en lenguaje y comunicación digital. También me interesa la traducción audiovisual.",
    },
    {
      id: "CardTraductoras",
      image: getImage(avatars.marcela),
      title: "Marcela Juan Meinardi",
      subtitle: "Traductora",
      text: "Soy egresada en 2011 de Traductora Pública Nacional de Inglés en la Facultad de Lenguas de la UNC, Córdoba, Argentina. Trabajo desde entonces en esta profesión que me apasiona. Me he especializado en...",
      collapsed:
        "Me he especializado en traducción médica y de protocolos clínicos, y he adquirido experiencia en documentación de educación, informes escolares y programas educativos. Además, soy docente en un instituto de educación media.",
    },
  ];

  return (
    <>
      <MDBCol className="d-flex justify-content-center align-items-center">
        {fundadora.map(({ id, image, title, subtitle, text }) => (
          <StaffLeader
            className="h-100"
            id={id}
            imageData={image}
            title={title}
            subtitle={subtitle}
            text={text}
          />
        ))}
      </MDBCol>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={false}
        autoPlaySpeed={20000}
        centerMode={false}
        className="mb-0 pb-0 pt-3 mt-0"
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass="carousel-item-padding-40-px"
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: (1, 2, 3),
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {staff.map(({ id, image, title, subtitle, text, collapsed }) => (
          <StaffCard
            className=""
            id={id}
            imageData={image}
            title={title}
            subtitle={subtitle}
            text={text}
            collapsed={collapsed}
          />
        ))}
      </Carousel>
    </>
  );
};
export default ARGATeamCarousel;
