import React from "react";
import { MDBRow, MDBContainer } from "mdb-react-ui-kit";

import ARGABenefitCard from "../../components/ARGABenefitCard";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Carousel from "react-multi-carousel";

const BenefitsSection = () => {
  const icons = useStaticQuery(
    graphql`
      query {
        icon01: file(relativePath: { eq: "S2-01.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon02: file(relativePath: { eq: "S2-02.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon03: file(relativePath: { eq: "S2-03.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon04: file(relativePath: { eq: "S2-04.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon05: file(relativePath: { eq: "S2-05.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon06: file(relativePath: { eq: "S2-06.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const benefitInfo = [
    {
      icon: getImage(icons.icon01),
      title: "Enfoque comunicativo",
      text: "Orientamos tu aprendizaje hacia las interacciones en otra lengua, como las que ocurren habitualmente en entornos laborales y de negocios.",
    },
    {
      icon: getImage(icons.icon02),
      title: "Aprendizaje basado en situaciones reales",
      text: "Te brindamos herramientas que podrás aplicar en situaciones cotidianas y significativas.",
    },
    {
      icon: getImage(icons.icon03),
      title: "Avances reales, medibles y concretos",
      text: "Hacemos seguimiento y devoluciones sobre tu cursado y tus exámenes para optimizar tu aprendizaje.",
    },
    {
      icon: getImage(icons.icon04),
      title: "Flexibilidad y personalización",
      text: "Diseñamos las clases en base a tus necesidades para lograr juntos una experiencia concreta y rápida.",
    },
    {
      icon: getImage(icons.icon05),
      title: "Generación de nuevos hábitos",
      text: "Trabajamos contigo para crear un sistema organizado de aprendizaje que te permita conseguir la constancia y dedicación requeridas para aprender un nuevo idioma.",
    },
    {
      icon: getImage(icons.icon06),
      title: "Acompañamiento académico y emocional",
      text: "Generamos un espacio para mejorar tu dominio del idioma, en un entorno confidencial y seguro.",
    },
  ];
  return (
    <div id="benefits">
    <MDBContainer breakpoint="xxl" className="text-center pt-5 d-none d-lg-block">
      <h2 className="text-primary py-3 fs-1 fw-bold">
        {" "}
        ¿En qué te beneficia aprender con ARGA?{" "}
      </h2>
      <hr
        className="mx-auto text-info"
        style={{ width: "100px", height: "3px" }}
      />
      <MDBRow className="text-center pt-1">
        {benefitInfo.map(({ icon, title, text }) => (
          <ARGABenefitCard 
            icon={icon} 
            title={title} 
            text={text} 
            classes="text-center d-flex justify-content-center align-items-top px-1"/>
        ))}
      </MDBRow>
    </MDBContainer>
    <MDBContainer breakpoint="xxl" className="text-center pt-5 d-lg-none">
      <h2 className="text-primary py-3 fs-1 fw-bold">
        {" "}
        ¿En qué te beneficia aprender con ARGA?{" "}
      </h2>
      <hr
        className="mx-auto text-info"
        style={{ width: "100px", height: "3px" }}
      />
      <MDBRow className="text-center pt-1 d-none d-lg-block">
        {benefitInfo.map(({ icon, title, text }) => (
          <ARGABenefitCard icon={icon} title={title} text={text} />
        ))}
      </MDBRow>
      <MDBRow className="text-center pt-1 d-lg-none">
      <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay={false}
          autoPlaySpeed={20000}
          centerMode={false}
          className="mb-3 pt-0 pb-5 mt-0 px-0 mx-0"
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 0,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 0,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 0,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={true}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {benefitInfo.map(({ icon, title, text }) => (
            <ARGABenefitCard 
              icon={icon} 
              title={title} 
              text={text} 
              classes="text-center d-flex justify-content-center align-items-top px-1 h-100" />
          ))}
        </Carousel>
      </MDBRow>
    </MDBContainer>
    </div>
  );
};

export default BenefitsSection;
