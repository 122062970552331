import React from "react";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ARGATestimonialCarousel = () => {
  const ARGATestimonialCarouselItem = ({
    classes,
    text,
    customerPic,
    customerName,
    customerTitle,
  }) => (
    <div className={classes}>
      {/* Carta de testimonios */}
      <div class="testcard bg-body">
        <div className='d-flex justify-content-between'>
          <MDBIcon fas icon="angle-left" className="text-black-50" style={{ fontSize: "1.5em" }} />
          <MDBIcon fas icon="angle-right" className="text-black-50" style={{ fontSize: "1.5em" }} />
        </div>
        <p class="lh-lg text-secondary text-start">
          <MDBIcon fas icon="quote-left" className="text-black-50" />
          {" " + text + " "}
          <MDBIcon fas icon="quote-right" className="text-black-50" />
        </p>
      </div>
      {/* La imagen del cliente */}
      <div class="testpic">
        <GatsbyImage
          image={customerPic}
          alt="client-2 picture"
          className="img-fluid rounded-circle"
        />{" "}
        {/* This should be a GatsbyImage component */}
      </div>
      {/* Nombre del cliente y puesto */}
      <div class="testid">
        <p class="text-primary fs-4 mb-0">{customerName}</p>
        <p class="lead fw-light text-secondary mb-0">{customerTitle}</p>
      </div>
    </div>
  );

  const customerPics = useStaticQuery(
    graphql`
      query {
        carolina: file(relativePath: { eq: "test/carolina.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        evans: file(relativePath: { eq: "test/evans.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        ana: file(relativePath: { eq: "test/ana.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        francisca: file(relativePath: { eq: "test/francisca.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        pedro: file(relativePath: { eq: "test/pedro.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        female: file(relativePath: { eq: "test/user-f.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        male: file(relativePath: { eq: "test/user-m.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  return (
    <div id="testimonios" className="text-center mb-5 pt-3">
      <MDBContainer fluid className="text-center">
        <div className="row text-center">
          <h2 className="fw-bold fs-1 text-primary mt-3 pb-3">Testimonios</h2>
          <hr
            className="mx-auto text-info"
            style={{ width: " 100px", height: "3px" }}
          />
          <p className="lead pt-0 text-secondary mb-0">Qué opinan de ARGA</p>
        </div>
        <MDBRow className="d-flex justify-content-center">
          <MDBCarousel
            interval={600000}
            showControls={true}
            fade
            className="my-3 pt-3 px-0 d-none d-lg-block"
            style={{ maxWidth: "800px",  minHeight: "495px" }}
          >
            <MDBCarouselInner>
              <ARGATestimonialCarouselItem
                data-mdb-interval="15000"
                classes="carousel-item active"
                text="Nuestras empresas Nisum Chile y Ki Teknology entregan el beneficio de clases de idiomas a todos sus colaboradores. Después de muchas búsquedas, reuniones y propuestas, decidimos contratar los servicios de ARGA. Su flexibilidad en horarios, control de asistencia y progresos, y enfoque en el rubro de nuestra compañía nos han permitido mantener un estándar alto y a nuestros colaboradores muy contentos con sus clases."
                customerPic={getImage(customerPics.carolina)}
                customerName="Carolina Escudero"
                customerTitle="People Analyst - Nisum"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Desde el comienzo he tenido contacto directo con la profesora y me ha facilitado la información, las asignaciones y material de estudio para ir adaptándome poco a poco. Lo que más me gusta es tener la opción de equivocarme y no sentir pena en hacerlo, ha sido una experiencia satisfactoria ya que con la metodología implementada por ARGA me di cuenta que realmente no es difícil y es cuestión de practicar."
                customerPic={getImage(customerPics.evans)}
                customerName="Evans Ladera Hernandez"
                customerTitle="QA Automation - Nisum"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Las clases me ayudaron mucho en el aprendizaje del italiano siendo que estaba prácticamente en cero cuando empecé. Desde el principio la profe empezó a dialogar en italiano lo cual me ayudó a escuchar y también hablar de manera constante. Cualquier duda tenía respuesta de la profe, siempre amable y muy dispuesta. Teníamos la oportunidad de conversar de nuestras vidas y diferentes temas, lo cual no las hacía aburridas ni monótonas, tampoco tan estructuradas. Aprendí muchísimo y sigo aprendiendo."
                customerPic={getImage(customerPics.ana)}
                customerName="Ana Gabriela Quiñones Amato"
                customerTitle="Clases de Italiano - Coach Nadia Pérez"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="It's was great to learn with native language teachers that understand working hours, being patient when it's necessary to drop the class for a urgent work matter and respecting holiday rescheduling the class."
                customerPic={getImage(customerPics.pedro)}
                customerName="Pedro Augusto de Almeida Caballero"
                customerTitle="Clases de Español - Coach Lara Peker"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Este es mi segundo año con ARGA, y pese a estudié por años inglés, siento que cada día aprendo algo nuevo. Las clases grupales son entretenidas y dinámicas, y nuestra profesora dedica tiempo a cada una de nuestra consultas y dudas. Me encanta!"
                customerPic={getImage(customerPics.francisca)}
                customerName="Francisca Palma"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Las clases son muy dinámicas, saliendo del esquema tradicional de clases estructuradas. Se puede aprender de manera divertida"
                customerPic={getImage(customerPics.male)}
                customerName="Juan Manuel Ibañez"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="ARGA me ha ayudado a trabajar en mi comunicación verbal y escrita en inglés. Las actividades, asignaciones y material de estudio han sido dinámicas y para mí, han logrado mejorar la gramática y fluidez para expresarme. Las conversaciones que se producen durante las clases funcionan para aprender nuevos términos, los cuales son claves para alimentar el vocabulario y ponerlos en práctica."
                customerPic={getImage(customerPics.male)}
                customerName="Gilberto Sirit"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Buenas clases, más enfocadas a la conversación. Es una buena opción para clases online con la cual practicar."
                customerPic={getImage(customerPics.male)}
                customerName="Freilin Manzano"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Ha sido una experiencia muy buena, he perdido el miedo/temor a decir cosas incorrectamente, el acompañamiento de mi coach ha sido de 10, siempre con la disposición necesaria para resolver las dudas que pueda tener."
                customerPic={getImage(customerPics.male)}
                customerName="Eduardo Andrade"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Llegue al programa con muchos desconocimiento, si bien aun debo seguir estudiándolo, he aprendido mucho desde mi inicio hasta ahora, ya que la coach es muy buena explicando y cuenta con la paciencia y las ganas de explicar la temática hasta quedar comprendida."
                customerPic={getImage(customerPics.male)}
                customerName="Wilder Guerrero"
                customerTitle="Clases de Inglés"
              />
            </MDBCarouselInner>
          </MDBCarousel>
          <MDBCarousel
            interval={600000}
            showControls={true}
            fade
            className="mt-3 pt-3 px-0 d-lg-none"
            style={{ maxWidth: "800px" }}
          >
            <MDBCarouselInner>
              <ARGATestimonialCarouselItem
                data-mdb-interval="15000"
                classes="carousel-item active"
                text="Nuestras empresas Nisum Chile y Ki Teknology entregan el beneficio de clases de idiomas a todos sus colaboradores. Después de muchas búsquedas, reuniones y propuestas, decidimos contratar los servicios de ARGA. Su flexibilidad en horarios, control de asistencia y progresos, y enfoque en el rubro de nuestra compañía nos han permitido mantener un estándar alto y a nuestros colaboradores muy contentos con sus clases."
                customerPic={getImage(customerPics.carolina)}
                customerName="Carolina Escudero"
                customerTitle="People Analyst - Nisum"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Desde el comienzo he tenido contacto directo con la profesora y me ha facilitado la información, las asignaciones y material de estudio para ir adaptándome poco a poco. Lo que más me gusta es tener la opción de equivocarme y no sentir pena en hacerlo, ha sido una experiencia satisfactoria ya que con la metodología implementada por ARGA me di cuenta que realmente no es difícil y es cuestión de practicar."
                customerPic={getImage(customerPics.evans)}
                customerName="Evans Ladera Hernandez"
                customerTitle="QA Automation - Nisum"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Las clases me ayudaron mucho en el aprendizaje del italiano siendo que estaba prácticamente en cero cuando empecé. Desde el principio la profe empezó a dialogar en italiano lo cual me ayudó a escuchar y también hablar de manera constante. Cualquier duda tenía respuesta de la profe, siempre amable y muy dispuesta. Teníamos la oportunidad de conversar de nuestras vidas y diferentes temas, lo cual no las hacía aburridas ni monótonas, tampoco tan estructuradas. Aprendí muchísimo y sigo aprendiendo."
                customerPic={getImage(customerPics.ana)}
                customerName="Ana Gabriela Quiñones Amato"
                customerTitle="Clases de Italiano - Coach Nadia Pérez"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="It's was great to learn with native speakers and teachers that understand the working hours, being patient when it's necessary to drop the class for a urgent work matter and respecting holiday rescheduling the class."
                customerPic={getImage(customerPics.pedro)}
                customerName="Pedro Augusto de Almeida Caballero"
                customerTitle="Clases de Español - Coach Lara Peker"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Las clases son muy dinámicas, saliendo del esquema tradicional de clases estructuradas. Se puede aprender de manera divertida"
                customerPic={getImage(customerPics.male)}
                customerName="Juan Manuel Ibañez"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Este es mi segundo año con ARGA, y pese a estudié por años inglés, siento que cada día aprendo algo nuevo. Las clases grupales son entretenidas y dinámicas, y nuestra profesora dedica tiempo a cada una de nuestra consultas y dudas. Me encanta!"
                customerPic={getImage(customerPics.female)}
                customerName="Francisca Palma"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="ARGA me ha ayudado a trabajar en mi comunicación verbal y escrita en inglés. Las actividades, asignaciones y material de estudio han sido dinámicas y para mí, han logrado mejorar la gramática y fluidez para expresarme. Las conversaciones que se producen durante las clases funcionan para aprender nuevos términos, los cuales son claves para alimentar el vocabulario y ponerlos en práctica."
                customerPic={getImage(customerPics.male)}
                customerName="Gilberto Sirit"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Buenas clases, más enfocadas a la conversación. Es una buena opción para clases online con la cual practicar."
                customerPic={getImage(customerPics.male)}
                customerName="Freilin Manzano"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                classes="carousel-item"
                data-mdb-interval="15000"
                text="Ha sido una experiencia muy buena, he perdido el miedo/temor a decir cosas incorrectamente, el acompañamiento de mi coach ha sido de 10, siempre con la disposición necesaria para resolver las dudas que pueda tener."
                customerPic={getImage(customerPics.male)}
                customerName="Eduardo Andrade"
                customerTitle="Clases de Inglés"
              />
              <ARGATestimonialCarouselItem
                className=""
                classes="carousel-item px-2"
                data-mdb-interval="15000"
                text="Llegue al programa con muchos desconocimiento, si bien aun debo seguir estudiándolo, he aprendido mucho desde mi inicio hasta ahora, ya que la coach es muy buena explicando y cuenta con la paciencia y las ganas de explicar la temática hasta quedar comprendida."
                customerPic={getImage(customerPics.male)}
                customerName="Wilder Guerrero"
                customerTitle="Clases de Inglés"
              />
            </MDBCarouselInner>
          </MDBCarousel>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default ARGATestimonialCarousel;
