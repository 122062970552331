import {
  MDBAccordion,
  MDBAccordionItem,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import React from "react";
import loadable from "@loadable/component";

const ARGAAccordion = ({
  id,
  title,
  services,
  formtitle,
  formtext,
  messageId,
  buttonColor,
  outline,
}) => {
  const ARGAFormModal = loadable(() => import("./ARGAFormModal"));

  return (
    <>
      <MDBCard id={id} className="py-4 px-0 my-1">
        <MDBCardHeader>
          <p className="fs-4 pb-2">{title}</p>
        </MDBCardHeader>
        <MDBCardBody className="px-0">
          <MDBAccordion className="text-primary rounded-3 px-0">
            {services.map(
              ({ collapseId, title, text, icon, sence, business }) => (
                <MDBAccordionItem
                  className="text-primary"
                  collapseId={collapseId}
                  headerTitle={sence ? title + ` ↔️ ✅ SENCE` : title}
                  headerClassName=""
                >
                  <MDBContainer className="px-0">
                    {/* <MDBRow className="pb-3 pt-2">
                      <MDBIcon
                        style={{ background: "DodgerBlue" }}
                        fas
                        icon={icon}
                        className="fs-3 text-light py-1"
                      />
                    </MDBRow> */}
                    <MDBRow className="text-start">
                      {business ? (
                        <>
                          <MDBTypography listUnStyled className="px-0 mb-2">
                            <li>
                              <ul>
                                <li>
                                  Clases adaptadas a los objetivos de la empresa
                                </li>
                                <li>Planes de 3, 6 y 9 meses</li>
                                <li style={{ fontWeight: "bold" }}>
                                  Franquicia SENCE: puedes descontar del pago de
                                  impuestos anual lo invertido en capacitación
                                </li>
                              </ul>
                            </li>
                          </MDBTypography>
                          {text}
                        </>
                      ) : (
                        text
                      )}
                    </MDBRow>
                  </MDBContainer>
                </MDBAccordionItem>
              )
            )}
          </MDBAccordion>
        </MDBCardBody>
        <MDBCardFooter>
          <ARGAFormModal
            title={formtitle}
            text={formtext}
            messageId={messageId}
            buttonColor={buttonColor}
            outline={outline}
          />
        </MDBCardFooter>
      </MDBCard>
    </>
  );
};

export default ARGAAccordion;
