import React from "react";

import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

import PostCard from "../../components/ARGAPostCard";
import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Carousel from "react-multi-carousel";

const NewsSection = ({ nodes }) => (
  <MDBContainer
    fluid
    id="BgNews"
    className="pb-2 px-0 pt-0 justify-content-center align-items-center"
  >
    <h2 className="text-primary flex-row pt-5 text-center fst-italic fs-1 fw-bold px-0 pb-2">
      {" "}
      Publicaciones recientes{" "}
    </h2>
    <hr
      className="mx-auto text-info my-4"
      style={{ width: "100px", height: "3px" }}
    />
    <MDBContainer breakpoint="xxl" className="pb-5">
      <MDBRow className="py-0">
        {nodes.slice(0, 3).map((node) => (
          <MDBCol lg={4} md={4} className="mb-2 py-2 px-1 d-none d-lg-block">
            <PostCard
              frontmatter={node.frontmatter}
              excerpt={node.excerpt}
              image={getImage(node.frontmatter.hero_image)}
              className="d-none d-lg-block"
            />
          </MDBCol>
        ))}
      </MDBRow>
      <MDBRow className="pb-4 pt-0">
        {/* {nodes.slice(0, 1).map((node) => (
          <MDBCol lg={4} md={4} className="mb-2 py-2 d-lg-none">
            <PostCard
              frontmatter={node.frontmatter}
              excerpt={node.excerpt}
              image={getImage(node.frontmatter.hero_image)}
              className=""
            />
          </MDBCol>
        ))} */}
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay={false}
          autoPlaySpeed={20000}
          centerMode={false}
          className="mb-3 pb-5 pt-0 mt-0 px-0 mx-0 d-lg-none"
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 0,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 0,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 0,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={true}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {nodes.slice(0, 3).map((node) => (
            <MDBCol className="px-1 h-100 ">
              <PostCard
                frontmatter={node.frontmatter}
                excerpt={node.excerpt}
                image={getImage(node.frontmatter.hero_image)}
                className=""
              />
            </MDBCol>
          ))}
        </Carousel>
      </MDBRow>
      <MDBRow className="text-center">
        <Link to="/blog">
          <MDBBtn
            size="lg"
            rippleColor="#65C0B7"
            rippleDuration={5000}
            color="primary"
            className="col-sm-3"
          >
            Ver más
          </MDBBtn>
        </Link>
      </MDBRow>
    </MDBContainer>
  </MDBContainer>
);

export default NewsSection;
