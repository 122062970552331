import React, { useState } from "react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { useForm } from "@formspree/react";
import {
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBCard,
  MDBTypography,
  MDBCardText,
  MDBTextArea,
  MDBIcon,
  MDBSelect,
  MDBContainer,
} from "mdb-react-ui-kit";
import { Link } from "gatsby";
//import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <MDBInput
        className="text-input mt-2"
        {...field}
        {...props}
        label={label}
      />
      {meta.touched && meta.error ? (
        <div className="small text-danger">{meta.error}</div>
      ) : null}
    </>
  );
};

const Phone = ({ name, label }) => {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;
  return (
    <PhoneInput
      international
      /* defaultCountry="CL" */
      className="mt-2 ps-3 py-1 rounded"
      placeholder={label}
      value={value}
      onChange={setValue}
      style={{ border: "solid", borderColor: "#bdbdbd", borderWidth: "thin" }}
    />
  );
};

const MultiSelect = ({ name, data }) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const [multiSelectValue, setMultiSelectValue] = useState(data);

  return (
    <MDBSelect
      multiple
      data={multiSelectValue}
      label="Quiero consultar sobre..."
      optionsSelectedLabel="Varias opciones elegidas"
      selectAllLabel="Seleccionar todo"
      className="mt-2"
      getValue={(values) => {
        const selectedOptions = [];
        values.map(({ value }) => selectedOptions.push(value));
        setValue(selectedOptions);
      }}
    />
  );
};

const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <MDBTextArea
        className="text-area mt-2"
        {...field}
        {...props}
        label={label}
      />
      {meta.touched && meta.error ? (
        <div className="small text-danger">{meta.error}</div>
      ) : null}
    </>
  );
};

const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <div className="pt-2">
      <label className="checkbox-input">
        <MDBCheckbox inline type="checkbox" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="small text-danger">{meta.error}</div>
      ) : null}
    </div>
  );
};

const ARGAFormik = ({ title, text, messageId }) => {
  const [state, handleSubmit] = useForm("mknyaqdo");
  if (state.succeeded) {
    return (
      <MDBCard className="pb-4 mt-0 shadow-5 justify-content-center align-items-center px-0">
        <MDBContainer className="px-4 py-0">
          <MDBCardText>
            <MDBTypography className="text-primary text-center fs-4 fst-normal pb-2 pt-4">
              Estamos en contacto
            </MDBTypography>
            <MDBTypography className="text-info text-center fw-bold fs-2 pb-2">
              ¡Gracias por tu confianza!
            </MDBTypography>
          </MDBCardText>
        </MDBContainer>
      </MDBCard>
    );
  }

  return (
    <MDBCard className="py-4 mt-0 shadow-5 justify-content-center align-items-center">
      <div>
        <MDBTypography className="text-success fw-bold fs-2 text-center mb-0 px-2">
          <MDBIcon
            style={{ transform: "rotate(180deg)" }}
            className="text-info mx-1 fs-1"
            fas
            icon="exclamation"
          />
          {text}
          <MDBIcon className="text-info mx-1 fs-1" fas icon="exclamation" />
        </MDBTypography>
        <MDBTypography className="text-secondary fs-5 fst-normal text-start pb-0 text-center px-4 my-0">
          {title}
        </MDBTypography>
      </div>
      <MDBContainer className="px-4 py-0">
        <Formik
          initialValues={{
            fullname: "",
            email: "",
            telephone: "",
            message: "",
            acceptedTerms: false,
          }}
          validationSchema={Yup.object({
            fullname: Yup.string()
              .max(30, "Debe ser de 30 caracteres o menos")
              .required("Requerido"),
            email: Yup.string()
              .email("Dirección de correo inválida")
              .required("Requerido"),
            /* telephone: Yup.string().matches(
                /^[0-9+.()-*]+$/,
                "Número de teléfono inválido"
                ), */
            acceptedTerms: Yup.boolean()
              .required("Required")
              .oneOf([true], "Requerido"),
          })}
          onSubmit={handleSubmit}
        >
          <Form>
            <TextInput
              label="Nombre y apellido"
              name="fullname"
              type="text"
              placeholder="Ana Martinez"
            />

            <TextInput
              label="Correo electrónico"
              name="email"
              type="email"
              placeholder="ana@gmail.com"
            />

            {/* <TextInput
              label="Teléfono"
              name="telephone"
              type="text"
              placeholder="+56 9 1234 5678"
            /> */}

            <Phone label="Teléfono" name="telephone" />

            <MultiSelect
              name="options"
              data={[
                {
                  text: "Recursos gratuitos 📚",
                  value: "Recursos gratuitos 📚",
                },
                {
                  text: "Noticias en mi correo",
                  value: "Noticias en mi correo",
                },
                {
                  text: "Descuentos especiales",
                  value: "Descuentos especiales",
                },
                {
                  text: "Clases y capacitaciones",
                  value: "Clases y capacitaciones",
                },
                {
                  text: "Franquicia SENCE ✅",
                  value: "Franquicia SENCE ✅",
                },
                {
                  text: "Traducciones",
                  value: "Traducciones",
                },
                {
                  text: "Subtitulados",
                  value: "Subtitulados",
                },
                {
                  text: "Interpretación simultánea remota",
                  value: "Interpretación simultánea remota",
                },
              ]}
            />

            <TextArea
              label="Mensaje"
              name="message"
              rows="2"
              placeholder="Déjanos tus comentarios y consultas aquí"
            />

            <Checkbox name="acceptedTerms">
              <span className="small px-0">
                Acepto la <Link to="/privacy">política de privacidad</Link>
              </span>
            </Checkbox>

            <MDBBtn className="mb-2 mt-2 btn-primary" type="submit" block>
              Enviar
            </MDBBtn>
          </Form>
        </Formik>
      </MDBContainer>
    </MDBCard>
  );
};

export default ARGAFormik;
