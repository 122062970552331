import { GatsbyImage } from "gatsby-plugin-image";
import { MDBCard, MDBCardBody, MDBCardText, MDBCol } from "mdb-react-ui-kit";
import React from "react";

const ARGABenefitCard = ({ icon, title, text, classes }) => (
  <MDBCol
    lg={4}
    className={classes}
  >
    <MDBCard
      id="CardWhite"
      className="shadow-2-strong mt-4 pt-4 align-items-center"
      style={{ maxWidth: "22rem" }}
    >
      <GatsbyImage
        image={icon}
        placeholder="tracedSVG"
        position="top"
        style={{ maxWidth: "8rem" }}
        alt="..."
        className="mt-4"
      />
      <MDBCardBody>
        <p className="text-primary fs-4 text-center">{title}</p>
        <MDBCardText className="text-secondary text-center px-2">
          {text}
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  </MDBCol>
);

export default ARGABenefitCard;
