import React from "react";
import { getImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import ARGAFormik from "../../components/ARGAFormik";

const HeaderSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "ARGAbg.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const image = getImage(data.file.childImageSharp.gatsbyImageData);

  // Use like this:
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      style={{ backgroundAttachment: "fixed", backgroundSize: "cover" }}
    >
      <MDBContainer style={{ minHeight: 900 }}>
        <div className="pt-5 d-none d-xxl-block"></div>
        <MDBRow className="d-flex justify-content-center align-items-start">
          <h1 className="visually-hidden">
            ARGA Intercultural - Mentoría en idiomas
          </h1>
          <MDBCol
            lg={7}
            className="mx-auto text-start align-items-center h-100 pt-5 mt-3"
          >
            {/** Título mobile */}
            <h2 className="text-primary fs-1 fw-bold pt-3 px-0 mx-0 d-block d-sm-none mt-3">
              <MDBTypography
                className="display-1 mb-2"
                style={{ fontWeight: 700 }}
              >
                Prepárate para
              </MDBTypography>
              <MDBTypography
                className="display-1 mb-2"
                style={{ fontWeight: 700 }}
              >
                tu proyecto
              </MDBTypography>
              <MDBTypography
                className="display-1 mb-2"
                style={{ fontWeight: 700 }}
              >
                <mark className="highlight text-primary p-0">
                  en otro idioma
                </mark>
              </MDBTypography>
            </h2>
            {/** FIN Título mobile */}
            {/** Título desktop XXL */}
            <div className="pt-4 mt-3 d-none d-xxl-block">
              <h2 className="fs-1 fw-bold pb-3 px-0 mx-0 d-none d-sm-block">
                <MDBTypography className="display-2 text-primary mb-0">
                  Prepárate para tu{" "}
                </MDBTypography>
                <MDBTypography className="display-2 text-primary m-0">
                  proyecto{" "}
                  <mark className="highlight text-primary m-0 px-0 pt-0">
                    en otro idioma
                  </mark>
                </MDBTypography>
              </h2>
            </div>
            {/** FIN Título desktop XXL */}
            {/** Título desktop XL */}
            <div className="mt-5 d-none d-xl-block d-xxl-none">
              <h2 className="fs-1 fw-bold pb-3 px-0 mx-0 d-none d-sm-block">
                <MDBTypography className="display-4 text-primary mb-0">
                  Prepárate para tu{" "}
                </MDBTypography>
                <MDBTypography className="display-4 text-primary m-0">
                  proyecto{" "}
                  <mark className="highlight text-primary m-0 px-0 pt-0">
                    en otro idioma
                  </mark>
                </MDBTypography>
              </h2>
            </div>
            {/** FIN Título desktop XL */}

            {/** Título desktop Medium-Tablet */}
            <div className="mt-5 d-none d-sm-block d-xl-none">
              <h2 className="fs-1 fw-bold pb-3 px-0 d-xl-none mx-0 d-none d-sm-block">
                <MDBTypography
                  className="display-4 text-primary mb-0"
                  style={{ fontWeight: 700 }}
                >
                  Prepárate para tu{" "}
                </MDBTypography>
                <MDBTypography
                  className="display-4 text-primary m-0"
                  style={{ fontWeight: 700 }}
                >
                  proyecto{" "}
                  <mark
                    className="highlight text-primary m-0 px-0 pt-0"
                    style={{ fontWeight: 700 }}
                  >
                    en otro idioma
                  </mark>
                </MDBTypography>
              </h2>
            </div>
            {/** FIN Título Medium-tablet */}

            <div className="pt-3 d-none d-xxl-block"></div>
            {/** Texto desktop */}
            <p className="fw-light text-secondary fs-4 d-none d-sm-block pb-0">
              Con{" "}
              <span className="text-primary" style={{ fontWeight: 700 }}>
                ARGA Intercultural
              </span>{" "}
              aprenderás
              <mark className="highlight text-secondary px-0 pt-0 m-0">
                {" "}
                buenas prácticas{" "}
              </mark>
              para desenvolverte con confianza en situaciones que requieren el
              uso de una lengua extranjera
            </p>
            {/** FIN Texto desktop */}
            {/** Texto mobile */}
            <p className="text-secondary fw-light fs-4 d-block d-sm-none pb-0 pt-4">
              Con{" "}
              <span className="text-primary" style={{ fontWeight: 700 }}>
                ARGA Intercultural
              </span>{" "}
              aprenderás
              <mark className="highlight text-secondary p-0 m-0">
                {" "}
                buenas prácticas{" "}
              </mark>
              para desenvolverte con confianza en situaciones que requieren el
              uso de una lengua extranjera
            </p>
            {/** FIN Texto mobile */}
            {/** Botones mobile */}
            <div className="pt-4 d-none d-xxl-block"></div>
            <div className="d-flex justify-content-start pt-3 d-block d-sm-none">
              <Link to="/#benefits">
                <MDBBtn className="p-2 me-3">Conoce más</MDBBtn>
              </Link>

              <Link to="/#firstForm">
                <MDBBtn className="p-2">Contáctanos</MDBBtn>
              </Link>
            </div>
            {/** FIN Botones mobile */}
            {/** Botones desktop */}
            <div className="text-center pt-3 d-none d-md-block">
              <Link to="/#benefits">
                <MDBBtn>Conoce más</MDBBtn>
              </Link>
            </div>
            {/** FIN Botones desktop */}
          </MDBCol>
          <MDBCol id="firstForm" lg={5} md={9} className="mx-auto py-5 px-0">
            <div className="mt-5 d-none d-xxl-block"></div>
            <div className="mt-3 d-none d-xl-block d-xxl-none"></div>
            <ARGAFormik
              title="Te responderemos a la brevedad, ¡sin chatbots! 😉"
              text="Queremos conocerte"
              messageId="messageFromHeader"
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="pt-5 d-none d-xxl-block"></div>
    </BackgroundImage>
  );
};

export default HeaderSection;
