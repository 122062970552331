import React from "react";

import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { graphql, useStaticQuery } from "gatsby";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import ARGAFormik from "../../components/ARGAFormik";
import { getImage, StaticImage } from "gatsby-plugin-image";

function ContactSection() {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "Empresas-04.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const image = getImage(data.file.childImageSharp.gatsbyImageData);

  // Use like this:
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      style={{backgroundAttachment: "fixed", backgroundSize: "cover"}}

    >
      <MDBContainer
        id="contact"
        fluid
        className="py-5"
        style={{ minHeight: 1000 }}
      >
        <h2 className="text-light flex-row pt-0 text-center fs-1 fw-bold px-0">
          {" "}
          Escríbenos{" "}
        </h2>
        <hr
          className="mx-auto text-info my-4"
          style={{ width: "100px", height: "3px" }}
        />
        <MDBContainer className="pb-2 pt-3 px-0">
          <MDBRow className="d-flex justify-content-center align-items-start">
            <MDBCol lg={5} md={8} className="pb-3 px-0">
              <ARGAFormik
                text="Esta es tu oportunidad"
                title="Recibe nuestros recursos gratuitos en tu correo 🎁"
                messageId="messageFromFooter"
              />
            </MDBCol>
            <MDBCol lg={5} className="d-flex justify-content-center px-1">
              <StaticImage
                className="rounded"
                src="../../assets/images/conoces-nuestros-recursos-gratuitos.jpg"
                height={600}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </BackgroundImage>
  );
}

export default ContactSection;
