import React from "react";

import loadable from "@loadable/component";
import ARGATestimonialCarousel from "../../components/ARGATestimonialCarousel";
import { MDBContainer } from "mdb-react-ui-kit";
import ARGACustomerSection from "../../components/ARGACustomerSection";
import ARGATeamCarousel from "../../components/ARGATeamCarousel";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import ARGAPresentation from "../../components/ARGAPresentation";

const PresentationSection = () => {
  const icons = useStaticQuery(
    graphql`
      query {
        icon01: file(relativePath: { eq: "S5-01.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon02: file(relativePath: { eq: "S5-02.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon03: file(relativePath: { eq: "S5-03.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const PresentationInfo = [
    {
      classNameCol: "",
      classNameRow: "mx-auto text-secondary text-center align-items-center g-3",
      icon: getImage(icons.icon01),
      text: "",
    },
    {
      classNameCol: "d-none d-lg-block",
      classNameRow:
        "mx-auto flex-row-reverse text-center text-secondary align-items-center g-3",
      icon: getImage(icons.icon02),
      text: "Somos proactivos y empáticos, y nos destacamos por nuestro sólido trabajo en equipo",
    },
    {
      classNameCol: "d-lg-none",
      classNameRow: "mx-auto text-secondary text-center align-items-center g-3",
      icon: getImage(icons.icon02),
      text: "Somos proactivos y empáticos, y nos destacamos por nuestro sólido trabajo en equipo",
    },
    {
      classNameCol: "",
      classNameRow: "mx-auto text-secondary text-center align-items-center g-3",
      icon: getImage(icons.icon03),
      text: "Nos enfocamos en la interculturalidad, ya que favorecemos el diálogo, la integración y la convivencia enriquecida entre culturas",
    },
  ];
  const ARGATestimonialVideos = loadable(() =>
    import("../../components/ARGATestimonialVideos")
  );
  return (
    <div className="empresas" id="presentation">
      <MDBContainer fluid className="pt-2 pb-0 px-0" style={{ maxWidth: "1600px" }}>
        <h2 className="text-primary py-3 mt-5 text-center fs-1 fw-bold">
          {" "}
          Acerca de ARGA{" "}
        </h2>
        <hr
          className="mx-auto text-info"
          style={{ width: "100px", height: "3px" }}
        />
        {PresentationInfo.map(({ classNameRow, classNameCol, icon, text }) => (
          <ARGAPresentation
            classNameRow={classNameRow}
            classNameCol={classNameCol}
            icon={icon}
            text={text}
          />
        ))}

        <h2 className="mt-5 text-primary text-center fs-1 fw-bold pb-3">
          {" "}
          El ARGA Team{" "}
        </h2>
        <hr
          className="mx-auto text-info mb-4"
          style={{ width: "100px", height: "3px" }}
        />
        <ARGATeamCarousel />
        <ARGATestimonialCarousel id="ARGATestimonialSection" />
        <ARGATestimonialVideos />
      </MDBContainer>
      <ARGACustomerSection />
    </div>
  );
};
export default PresentationSection;
