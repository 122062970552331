import React from "react"
import { graphql } from "gatsby"

import Seo from '../components/SEO';
import HeaderSection from '../sections/index/Section 1 - Header';
import BenefitsSection from '../sections/index/Section 2 - Benefits';
import ServicesSection from '../sections/index/Section 3 - Services';
import NewsSection from "../sections/index/Section 4 - News";
import PresentationSection from '../sections/index/Section 5 - Presentation';
import ContactSection from '../sections/index/Section 6 - Contact';

const Index = ({ data }) => (
    <>
    <Seo title='Aprender ingles online es sencillo en el Instituto de inglés ARGA'/>
    <main>
       <HeaderSection/>
       <BenefitsSection />
       <ServicesSection />
       <PresentationSection />
       <NewsSection nodes={data.allMarkdownRemark.nodes} />
       <ContactSection />
    </main>
    </>
  );


export const pageQuery = graphql`
{
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 3) {
    nodes {
      excerpt
      frontmatter {
        title
        slug
        date(formatString: "DD/MM/YYYY")
        author
        status
        type
        hero_image {
          childImageSharp {
            gatsbyImageData(width: 1170, height: 390)
          }
        }
      }
    }
  }
}
`

export default Index