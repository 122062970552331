import React from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { GatsbyImage } from "gatsby-plugin-image";

const ARGAPresentation = ({ classNameCol, classNameRow, icon, text }) => (
  <MDBRow className={classNameRow} style={{ maxWidth: "1100px" }}>
    <MDBCol lg={6} className={classNameCol}>
      <GatsbyImage
        className="mt-1 mw-100"
        image={icon}
        placeholder="tracedSVG"
        position="top"
        alt="..."
      />{" "}
    </MDBCol>
    {text === "" ? (
      <MDBCol lg={6} className={classNameCol}>
        <p className="text-start pt-2" style={{ fontSize: "1.30rem" }}>
          <span className="text-primary" style={{ fontWeight: 700 }}>
            ARGA
          </span>{" "}
          es un equipo de profesionales con más de diez años de experiencia en
          enseñanza y traducciones en los idiomas inglés, chino, español,
          portugués, italiano, francés y alemán
        </p>
      </MDBCol>
    ) : (
      <MDBCol lg={6} className={classNameCol}>
        <p className="fs-5 text-start pt-2" style={{ fontSize: "1.30rem" }}>
          {text}
        </p>
      </MDBCol>
    )}
  </MDBRow>
);
export default ARGAPresentation;
