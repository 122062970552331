import React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import Carousel from "react-multi-carousel";
import { MDBCol } from "mdb-react-ui-kit";


const ARGACustomerSection = () => {
  const brands = useStaticQuery(
    graphql`
    query {
      allFile(filter: {relativePath: {regex: "/Clientes/"}}) {
        nodes {
          childImageSharp {
            gatsbyImageData(width: 195, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    `
  );

  const brandImages = [];
  brands["allFile"]["nodes"].map(node => brandImages.push(getImage(node)));
  
  return (
    <>
      <div className="py-5 bg-white ">
        <div className="container">
          <h2 className="text-primary pb-3 mt-2 text-center fs-1 fw-bold">
            {" "}
            Confían en ARGA{" "}
          </h2>
          <hr
            className="mx-auto text-info mb-0"
            style={{ width: "100px", height: "3px" }}
          />
          <div className="row justify-content-center pt-2 pb-0 d-lg-none">
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={2000}
              centerMode={true}
              className="my-0 pb-0 pt-3"
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass="carousel-item-padding-40-px d-flex align-items-center"
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 5,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 0,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {brandImages.map((brand) => (
                <MDBCol className="pe-3">
                  <GatsbyImage
                    image={brand}
                    alt="company logo"
                    className="img-fluid"
                    placeholder="tracedSVG"
                  />
                </MDBCol>
              ))}
            </Carousel>
          </div>
          <div className="row justify-content-center pt-2 pb-4">
            {brandImages.map((brand) => (
              <div className="col-md-2 companies  d-none d-lg-block">
                <GatsbyImage
                  image={brand}
                  alt="company logo"
                  className="img-fluid"
                  placeholder="tracedSVG"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ARGACustomerSection;
