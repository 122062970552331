import React from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdb-react-ui-kit";
import ARGAAccordion from "../../components/ARGAAccordion";

const ServicesSection = () => {
  const peopleServices = [
    {
      collapseId: 1,
      title: "🖊️ Clases personalizadas",
      text: "¿Necesitas comunicarte en otro idioma pero no logras encontrar un método de aprendizaje adecuado para ti y tus objetivos? Con la metodología de ARGA basada en la andragogía (enseñanza para adultos) y el matching ideal, en el que elegimos al coach que mejor se adapta a tus necesidades y tu área profesional, desbloquearemos tu potencial. Podrás alcanzar tus objetivos de una manera más rápida y efectiva con nuestras clases en línea en horarios flexibles. Ya sea que busques aprender otro idioma para trabajar, viajar o simplemente como meta personal. ¡Es hora de comenzar!",
      icon: "chalkboard-teacher",
    },
    {
      collapseId: 2,
      title: "📚 Traducciones de documentos",
      text: "Si necesitas el servicio de traducción de documentos para trámites migratorios o postulaciones a trabajos en el extranjero, por ejemplo, es fundamental dejar este trabajo en manos de un equipo de expertos, debido a que en la traducción se incluyen características importantes del idioma de destino, así como del tipo de texto, el tono y los aspectos culturales. Desde ARGA, con más de diez años de experiencia y más de un millón de palabras traducidas, te ofrecemos resultados que superan altos estándares de calidad, sin perder de vista tus necesidades y acompañándote en cada paso.",
      icon: "file-signature",
    },
    {
      collapseId: 3,
      title: "🆘 Mentoría SOS",
      text: "Las buenas oportunidades a veces surgen de manera inesperada y no tenemos demasiado tiempo para prepararnos. Con la Mentoría SOS de ARGA podrás abordar esta situación en el tiempo que lo necesites, mediante sesiones de entrenamiento para desempeñarte de manera sobresaliente en esa entrevista tan importante para tu futuro laboral. Te brindaremos tips y herramientas, haremos juegos de roles con preguntas típicas que los entrevistadores realizan y te daremos sugerencias sobre cómo contestarlas.",
      icon: "ambulance",
    },
    {
      collapseId: 4,
      title: "🧠 Plan Autodidacta Guiado",
      text: "Si deseas aprender un idioma, pero tus horarios o tu estilo de vida no te permiten tomar clases en días y horarios fijos, esta es la opción adecuada para ti. El Plan Autodidacta Guiado Asincrónico de ARGA se elabora de acuerdo con tus necesidades e intereses. Es un plan a medida según tu nivel y trabajamos las cuatro habilidades: escucha, habla, lectura y escritura. Por cada sesión recibes feedback de tu coach para tener un aprendizaje más efectivo. También puedes contratar el Plan Blended, que consta del Plan Autodidacta Guiado Asincrónico más sesiones sincrónicas adicionales, ideal para que sumes sesiones en vivo con un coach de ARGA a tu formación autodidacta.",
      icon: "street-view",
    },
  ];

  const businessServices = [
    {
      collapseId: 1,
      title: "💡 Capacitaciones en idiomas para equipos ",
      text: "Diseñamos nuestros cursos de acuerdo con las necesidades de tu empresa y el área profesional en la cual se desempeñan los colaboradores de cualquier cargo. En nuestras capacitaciones incorporamos vocabulario y expresiones relacionadas al sector empresarial para practicar con situaciones reales de la vida laboral. Sumado a esto, el intercambio que se genera en las clases entre los colaboradores también tiene un impacto positivo en la relación laboral. Por todo esto, desde ARGA te ofrecemos sesiones cien por ciento personalizadas de acuerdo con los objetivos de negocios de tu empresa, las habilidades actuales y potenciales de los colaboradores y el sector de la compañía. También recibirás acompañamiento y feedback constantes, exámenes e informes de progreso y certificados de asistencia.",
      icon: "users",
      sence: true,
      business: true,
    },
    {
      collapseId: 2,
      title: "📔 Traducciones especializadas",
      text: "La necesidad de un servicio de traducción para empresas surge del proceso de globalización que vivimos en nuestra sociedad de hoy en día, así como de los deseos de internacionalización de las empresas para llegar a más potenciales clientes en un mercado global. Para ello, se hace imprescindible contar con los servicios de traductores profesionales. En ARGA nos guiamos por normas de calidad para garantizar procesos fluidos, y así cumplir con los tiempos de entrega mediante un trabajo exhaustivo pero ágil.",
      icon: "file-contract",
    },
    {
      collapseId: 3,
      title: "🖥️ Subtitulado de videos ",
      text: "Si tu empresa busca expandirse a nuevos mercados, el subtitulado de videos es una de las maneras más efectivas de transmitir contenido audiovisual. Desde ARGA ponemos a disposición de tu empresa nuestra experiencia en subtitulado para obtener un resultado óptimo en cuanto a visualización y sincronización con el sonido.",
      icon: "film",
    },
    {
      collapseId: 4,
      title: "💬 Interpretación simultánea remota",
      text: "Este servicio marcará la diferencia a la hora de llevar a cabo conferencias, charlas, capacitaciones, simposios y otros eventos virtuales de tu empresa en los cuales los participantes son de distintas nacionalidades. La interpretación simultánea remota de ARGA permite que participantes y oradores puedan escuchar los mensajes en su idioma nativo, provistos por nuestros intérpretes en tiempo real. Al ser remoto, permite a una gran cantidad de personas participar en simultáneo desde diferentes partes del mundo a través de una plataforma virtual, sin incurrir en otros costos (como por ejemplo, el de un alquiler de cabina).",
      icon: "laptop-code",
    },
  ];

  return (
    <MDBContainer
      breakpoint="xxl"
      id="services"
      className="text-center text-white pt-5"
      style={{ minHeight: 800 }}
    >
      <h2 className="text-primary pb-3 fs-1 mt-4 fw-bold">
        {" "}
        ¿Cómo te acompañamos en ARGA?{" "}
      </h2>
      <hr
        className="mx-auto text-info"
        style={{ width: "100px", height: "3px" }}
      />
      <MDBRow className="d-flex justify-content-center align-items-top pt-0 mt-0 gy-3">
        <MDBCol lg={6} className="px-1">
          <ARGAAccordion
            id="CardPersonas"
            title="Servicios para PERSONAS"
            services={peopleServices}
            formtext="Consúltanos"
            formtitle="Potenciamos tu dominio de un nuevo idioma 🚀"
            messageId="messageFromPeopleServices"
            buttonColor="light"
            outline={true}
          />
        </MDBCol>
        
        <MDBCol lg={6} className="px-1">
          <ARGAAccordion
            id="CardEmpresas"
            title="Servicios para EMPRESAS"
            services={businessServices}
            formtext="Fideliza a tus talentos"
            formtitle="Tenemos descuentos especiales para equipos 🤝"
            messageId="messageFromBusinessServices"
            buttonColor="light"
            outline={true}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ServicesSection;
